import React from 'react'
import { Link } from 'gatsby'
import Video from '../../video/video'
import * as linkStyle from '../../../styles/components/links.module.css'
import Icon from '../../icons'
import { calculateRawContent } from '../../../lib/helpers'
import { Image } from '../../Image'
// eslint-disable-next-line import/no-unresolved
import { useWindowSize } from '../../../hooks/useWindowSize.hook'

const SimpleCard = ({ data, view, section, options, styles, display = true, short, page }) => {
  const { width } = useWindowSize()

  const contentTypeTitle = (type) => {
    switch (type) {
      case 'written':
        return 'Article'
      case 'video':
        return 'Video'
      case 'audio':
        return 'Audio'
      case 'post':
        return 'News'
      default:
        return ''
    }
  }

  const readsTime = (data?.timeReads && data._type !== 'written')
    ? (() => {
      switch (data._type) {
        case 'audio':
          return `${data?.timeReads} listen`
        case 'video':
          return `${data?.timeReads} episode`
        case 'post':
          return `${data?.timeReads} read`
        default:
          return ''
      }
    })()
    : (data._type === 'written'
      ? calculateRawContent(data._rawContent)
      : '')

  const link = () => {
    if (data.openLink) return data.openLink
    if (data._type === 'audio') return (data?.series && `/content-hub/${data.series?.slug?.current}/`)
    if (data._type === 'post') return (data?.link && data.link)
    return (data.slug && `/content-hub/${data._type}/${(data.slug?.current)}/`)
  }
  const resultLink = link()

  return (
    <div className={`${styles.card} ${styles.simpleCard} ${styles[view]} ${styles[section]}`}>
      {data._type === 'video' && section === 'featured' ? (
        <Video videoId={data.videoId} type={data.youtubePlayer ? 'youtube' : 'standard'} addClass='featuredVideo' mute={1} />
      ) : (
        <Link
          to={resultLink}
          className={styles.cardLink}
          target={options.newTab ? '_blank' : '_self'}
        >
          {(data?.featureImage?.asset._id || data?.mainImage?.asset._id) &&
            (
              <Image
                imgObj={data?.featureImage || data?.mainImage}
                props={{
                  alt: data.title,
                  width: options?.imgSizes ? options.imgSizes[0] : null,
                  height: options?.imgSizes ? options.imgSizes[1] : null
                }}
              />
            )}
        </Link>)}
      <div className={styles.cardContent} style={page === 'home' ? { justifyContent: 'flex-start' } : {}}>
        {page !== 'home' && <div className={styles.cardTags}>
          {(!data.openLink && contentTypeTitle(data._type) !== '')
            ? <span>{contentTypeTitle(data._type)}</span> : <span>Report</span>}
          {(data.tags?.length > 0 && options?.tags === true && page !== 'home') && (
            <div className={styles.tags}>
              {data.tags.map((tag, tagIndex) => (
                <Link
                  key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`}
                  className={styles.tag}
                >
                  {tag.title}
                </Link>
              ))}
            </div>
          )}
        </div>}
        <Link
          style={page === 'home' ? { fontSize: width > 768 ? 24 : 16, display: section === 'featured' ? 'none' : 'block' } : {}}
          to={resultLink}
          target={options.newTab ? '_blank' : '_self'}
          className={styles.title}
        >
          {data.title}

          {view === 'simpleX' || (readsTime && options?.reads === true) ? (
            <div className={styles.minRead}>{readsTime}</div>
          ) : ''}
        </Link>
        {(page === 'home' && section !== 'featured') && <div style={{ marginTop: width > 768 ? 90 : 0 }}>
          <Link
            style={{ color: '#2F2F2F', fontSize: 18 }}
            to={data.openLink ? data.openLink : (data.slug && `/content-hub/${data._type}/${data.slug?.current}/`) || (data.link && `${data.link}`)}
            target='_blank'
            className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow} ${linkStyle.linkArrowHomePage}`}
          >read
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${linkStyle.homeIcon} ${styles.icon}`}>
                <Icon width={12} symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
        </div>}
      </div>
    </div>
  )
}

export default SimpleCard
